import React, { Component } from 'react';
import logo from './logo.png';
import { Navbar, NavbarToggler, Collapse } from 'reactstrap';
import $ from 'jquery';
import { ucFirst } from '../utils';
import { RouterContext } from './RouterContext';
import NavRightItems from './NavRightItems';

export class Header extends Component {
  static contextType = RouterContext;

  state = {
    isOpen: false
  };

  toggleNavbar = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  getParentPage() {
    return this.context.history.location.pathname.split('/')[1];
  }

  getMenuName() {
    return ucFirst(this.getParentPage());
  }

  getClickHandler(to) {
    // Use own click handler in NavLink to close menu
    return (event) => {
      event.preventDefault();
      this.context.history.push(to);
      // Toggle navbar when toggler is displayed
      if (window.getComputedStyle($('.navbar-toggler')[0]).getPropertyValue('display') === 'block') {
        this.toggleNavbar();
      }
    }
  }

  render() {
    return (
      <header>
        <a href="/" className="position-fixed navbar-brand">
          <img src={logo} alt="Logo" height="80" />
        </a>
        <Navbar color="light" fixed="top" light expand="md">
          <NavbarToggler onClick={this.toggleNavbar} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <NavRightItems />
          </Collapse>
        </Navbar>
      </header>
    )
  }
}
