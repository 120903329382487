import { useEffect } from 'react';
import { client } from '../Client';
import { showError } from '../notify';

export default function useLoadData(url, onResponse, method = 'GET') {
  useEffect(() => {
    client.api(method, url)
      .then(resp => onResponse(resp))
      .catch(showError);
  }, []);
}
