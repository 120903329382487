import React, { useState, useEffect } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
  Form, /* FormGroup, CustomInput,*/ Alert
} from 'reactstrap';
import useLoadData from './useLoadData';
// import formatPrice from './format_price';

export default function LabReportsModal({ isOpen, toggle, value, onChange }) {
  const [selected, setSelected] = useState(value);
  const [reports, setReports] = useState([]);

  useLoadData('/reports', resp => setReports(resp.data));
  useEffect(() => {
    setSelected(value);
  }, [value]);

  function createLabReports(event) {
    event.preventDefault();
    event.stopPropagation();

    onChange(selected);
    toggle();
  }

  function onCancel() {
    setSelected(value);
    onChange(value);
    toggle();
  }
/*
  function handleInputChange(checked, report) {
    setSelected(checked ? selected.concat(report) : selected.filter(({ name }) => name !== report.name));
  }
*/
  return (
    <Modal isOpen={isOpen} backdrop="static" keyboard={false} >
      <Form onSubmit={createLabReports} autoComplete="off" >
        <ModalHeader>Reports</ModalHeader>
        <ModalBody>
          <Alert color="info">Currently in development</Alert>
          {/*
          <p className="text-info">Please select what kind of reports do you need</p>
          <FormGroup>
            {reports.map((report, idx) =>
              <CustomInput
                key={idx} type="checkbox" label={`${report.name} - ${formatPrice(report.price)}`}
                id={`checkbox-${idx}`}
                checked={selected.includes(report)}
                onChange={e => handleInputChange(e.target.checked, report)}
              />
            )}
          </FormGroup>
          */}
        </ModalBody>
        <ModalFooter>
          {/* <Button type="submit" color="primary">Save</Button>{' '} */}
          <Button color="secondary" onClick={onCancel}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
