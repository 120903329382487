import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

export default function ProjectProgressChart({ data = {}, chartContainer = 'project-progress-chart' }) {
  useEffect(() => {
    const { series, categories } = data;

    const chart = Highcharts.chart(chartContainer, {
      title: {
        text: undefined
      },
      yAxis: {
        title: {
          text: undefined
        }
      },
      xAxis: {
        labels: {
          enabled: false
        },
        categories
      },
      legend: {
        // enabled: false,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false
          }
        }
      },

      credits: {
        enabled: false
      },

      series,

      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }]
      }
    });

    return function destroyChart() {
      chart.destroy();
    };
  }, [data]);

  return (
    <div id={chartContainer} />
  );
}
