import React, { useState } from 'react';
import UserContext from './UserContext';
import { client } from './Client';

export default function UserProvider(props) {
  const [user, setUser] = useState(client.getUser());

  function onTokenChange(token) {
    client.setToken(token);
    setUser(client.getUser());
  }

  return (
    <UserContext.Provider
      value={{
        user,
        onTokenChange
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
