import crypto from 'crypto';
import React, { useState } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
  Form, FormGroup, Input, Label, Row, Col, InputGroup,
  InputGroupAddon
} from 'reactstrap';
import FeasibilityCountrySelect from './FeasibilityCountrySelect';
import { notify } from '../notify';

const sampleDefaultValues = {
  locked: false,
  country: '',
  completes: 500,
  ir: 100,
  loi: 15,
  notes: '',
  sample: {
    age: {
      type: 'nat',
      values: [18, 64],
      quota: {}
    },
    gender: {
      type: 'nat',
      values: ['m', 'f'],
      quota: {}
    },
    region: {
      type: 'nat',
      values: [],
      quota: {}
    },
    device: {
      values: ['pc', 'tablet', 'mobile']
    }
  }
};

function getDefaultSampleValues() {
  return JSON.parse(JSON.stringify(sampleDefaultValues));
}

export default function SampleMarketModal({ isOpen, toggle, onSave }) {
  const [values, setValues] = useState(getDefaultSampleValues());

  function createMarket(event) {
    event.preventDefault();
    event.stopPropagation();

    if(values.country) {
      const key = crypto.randomBytes(4).toString('hex');
      onSave({ ...values, key });
      onClose();
    } else {
      notify.error('You have to select a country first');
    }
  }

  function updateValues(newValues) {
    setValues({ ...values, ...newValues });
  }

  function onValueChange(event) {
    const { name, value } = event.target;

    updateValues({ [name]: value });
  }

  function onClose() {
    updateValues(getDefaultSampleValues());
    toggle();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" keyboard={false} >
      <Form onSubmit={createMarket} autoComplete="off" >
        <ModalHeader toggle={onClose}>Create Target Group</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="country">Country</Label>
            <FeasibilityCountrySelect
              name="country"
              placeholder="Select country"
              value={values.country} onChange={updateValues}
              required
            />
          </FormGroup>

          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="completes">Completes</Label>
                <Input
                  type="number" name="completes" min="1"
                  value={values.completes}
                  onChange={onValueChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="ir">Incidence Rate</Label>
                <InputGroup>
                  <Input
                    type="number" name="ir" min="1" max="100"
                    value={values.ir}
                    onChange={onValueChange}
                    required
                  />
                  <InputGroupAddon addonType="append">%</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="loi">Length of Interview</Label>
                <InputGroup>
                  <Input
                    type="number" name="loi" min="1" max="45"
                    value={values.loi}
                    onChange={onValueChange}
                    required
                  />
                  <InputGroupAddon addonType="append">min</InputGroupAddon>
                </InputGroup>
              </FormGroup>  
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">Save</Button>{' '}
          <Button color="secondary" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export {
  getDefaultSampleValues
};
