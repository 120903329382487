import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class Cell extends Component {
  static propTypes = {
    column: PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      dataField: PropTypes.string.isRequired,
      sortable: PropTypes.bool.isRequired,
      asc: PropTypes.bool,
      renderer: PropTypes.func,
      params: PropTypes.object,
      align: PropTypes.string,
      width: PropTypes.number.isRequired,
      exactWidth: PropTypes.bool.isRequired,
      className: PropTypes.string
    }),
    row: PropTypes.object.isRequired,
    summary: PropTypes.bool,
    adjustTotal: PropTypes.string,
    rowCount: PropTypes.number,
    rowIndex: PropTypes.number
  };

  static defaultProps = {
    summary: false
  };

  getData() {
    return this.props.row[this.props.column.dataField];
  }

  getContent() {
    if (this.props.summary && !this.props.column.summarize) {
      return <div className="cell"></div>;
    }

    if (this.props.column.renderer) {
      return (
        <this.props.column.renderer
          row={this.props.row}
          data={this.getData()}
          summary={this.props.summary}
          column={this.props.column}
          rowCount={this.props.rowCount}
          rowIndex={this.props.rowIndex}
          currentPage={this.props.currentPage}
          pageSize={this.props.pageSize}
          {...this.props.column.params}
        />
      );
    }

    return <div className="cell">{this.getData()}</div>;
  }

  render() {
    let alignClass = '';
    if (this.props.column.align) {
      alignClass = `justify-content-${this.props.column.align}`;
    }

    const style = {
      width: this.props.column.width
    };

    if (this.props.column.exactWidth) {
      style.flexGrow = 0;
    }

    return (
      <div
        className={classNames(
          'grid-cell', 'd-flex', 'align-content-center', 'align-items-center', alignClass, this.props.column.className
        )}
        style={style}
      >
        {this.getContent()}
      </div>
    );
  }
}
