import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './global';

// Styles
// Import Bootstrap
import 'bootstrap/dist/css/bootstrap.css';

// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';

// React-Toastify css for notifications
import 'react-toastify/dist/ReactToastify.css';

// Import Custome styles
import './index.css';

import { PrivateRoute, LoginForm, Logout, App } from './application/site';

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/login" name="Login" component={LoginForm} />
      <Route exact path="/logout" name="Logout" component={Logout} />
      <PrivateRoute component={App} />
    </Switch>
  </Router>, document.getElementById('root')
);
