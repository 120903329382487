import React, { useState, useContext, useEffect } from 'react';
import UserContext from './UserContext';
import CheckoutContext from './CheckoutContext';
import createSurveyMarket from './welcome/create_survey_market';

const defaultValues = {
  diy_survey: null, sampling: {}, reports: [], special: '', project_name: '', po: '', job_number: ''
};

function hasOwnProperty(obj, property) {
  return Object.prototype.hasOwnProperty.call(obj, property);
}

export default function CheckoutProvider({ children }) {
  const storedValues = localStorage.getItem('checkout');
  const { user: { diy_survey } } = useContext(UserContext);
  const [checkout, setCheckout] = useState(
    storedValues ? JSON.parse(storedValues) : { ...defaultValues }
  );

  useEffect(() => {
    const { sampling = {} } = checkout;
    const update = { diy_survey };
    // remove sample
    if (!diy_survey && hasOwnProperty(sampling, 'jlsurvey')) {
      Object.assign(update, {
        sampling: Object.fromEntries(
          Object.entries(sampling).filter(([key,]) => key !== 'jlsurvey')
        )
      });
    }

    if (diy_survey && !diy_survey.ownsample) {
      const market = createSurveyMarket(diy_survey);
      Object.assign(update, { sampling: { ...sampling, [market.key]: market }});
    }

    updateCheckout(update);
  }, [diy_survey]);

  useEffect(() => {
    localStorage.setItem('checkout', JSON.stringify(checkout));
  }, [checkout]);

  function updateCheckout(obj) {
    setCheckout({ ...checkout, ...obj });
  }

  function resetCheckout() {
    setCheckout({ ...defaultValues });
  }

  return (
    <CheckoutContext.Provider
      value={{
        checkout,
        updateCheckout,
        resetCheckout
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}
