import React from 'react';
import { Row, Col } from 'reactstrap';
import WelcomeRow from './WelcomeRow';
import DiySurveyCard from './DiySurveyCard';
import SamplingCard from './SamplingCard';
import SpecialServicesCard from './SpecialServicesCard';
import LabReportsCard from './LabReportsCard';
import CheckoutRow from './CheckoutRow';
import ProjectGridFilter from './ProjectGridFilter';

export default function BaseScreen() {
  return (
    <div>
      <WelcomeRow />
      <Row className="text-center">
        <Col><h4>What do you want to do today?</h4></Col>
      </Row>
      <Row>
        <div className="card-deck welcome-card-row">
          <DiySurveyCard />
          <SamplingCard />
          <LabReportsCard />
          <SpecialServicesCard />
        </div>
      </Row>
      <CheckoutRow />
      <ProjectGridFilter />
    </div>
  );
}