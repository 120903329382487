import React, { useContext } from 'react';
import {
  CardImg, CardTitle, CardText, Card, CardBody, Button 
} from 'reactstrap';
import reportImg from './reports.png'
import LabReportsModal from './LabReportsModal';
import CardButton from './CardButton';
import SuccessCheck from './SuccessCheck';
import useModal from './useModal';
import CheckoutContext from '../CheckoutContext';

export default function LabReportsCard() {
  const { checkout: { reports = [] }, updateCheckout } = useContext(CheckoutContext);
  const [showModal, toggleModal] = useModal();

  return (
    <React.Fragment>
      <Card className="text-center">
        {reports.length > 0 && <SuccessCheck />}
        <CardImg top className="welcome-card-img" src={reportImg} alt="Lab Report" />
        <CardBody className="d-flex flex-column">
          <CardTitle tag="h5">Lab Report</CardTitle>
          <CardText tag="div">syndicated data, industry reports, etc</CardText>
          {reports.length === 0 ? (
            <CardButton>
              <Button outline onClick={toggleModal}>START</Button>
            </CardButton>
          ) : (
            <CardButton>
              <Button outline onClick={toggleModal}>RESTART</Button>
              <Button outline color="warning" onClick={() => updateCheckout({ reports: [] })}>Remove</Button>
            </CardButton>
          )}
        </CardBody>
      </Card>
      <LabReportsModal
        isOpen={showModal} toggle={toggleModal}
        value={reports} onChange={value => updateCheckout({ reports: value })}
      />
    </React.Fragment>
  );
}
