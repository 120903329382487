import React from 'react';
import { Alert } from 'reactstrap';

const RouteNotFound = ({ location }) => (
  <Alert color="warning">
    <strong>Warning!</strong> Route not found.
  </Alert>
);

export default RouteNotFound;
