import React, { useContext } from 'react';
import classNames from 'classnames';
import surveyImg from './survey.png';
import { CardImg, CardTitle, CardText, Card, CardBody, Button } from 'reactstrap';
import CardButton from './CardButton';
import SuccessCheck from './SuccessCheck';
import UserContext from '../UserContext';
import { client } from '../Client';

function SurveyInfo({ ir, loi, template, minCompletes }) {
  return (
    <div className="text-left">
      Link info:
      <ul>
        <li>IR: {ir}</li>
        <li>LOI: {loi}</li>
        <li>Template: {template}</li>
        <li>Minimum completes: {minCompletes}</li>
      </ul>
    </div>
  );
}

function TestSurvey({ survey_name, test_link }) {
  return (
    <div className="test-survey">
      <div>
        Custome survey - {survey_name}
      </div>
      <div>
        <a
          className="btn btn-outline-secondary btn-sm" href={test_link}
          target="_blank" role="button" rel="noopener noreferrer"
        >
          TEST SURVEY
        </a>
      </div>
    </div>
  );
}

function isEmpty(value) {
  return value === null || value === undefined || value === '';
}

function DiySurveyStartLink({ diy_url, hash, label = 'START' }) {
  const startUri = new URL(diy_url);
  startUri.searchParams.set('hash', hash);

  return (
    <a className="btn btn-outline-secondary" href={startUri.toString()} role="button">{label}</a>
  );
}

export default function DiySurveyCard() {
  const { user: { hash, diy_survey, diy_url }, onTokenChange } = useContext(UserContext);
  const noSurvey = isEmpty(diy_survey);
  const hasSurvey = !noSurvey;
  const hasUrl = !isEmpty(diy_url);

  function onRemoveSurvey() {
    client.api('DELETE', '/diy/survey')
      .then(resp => onTokenChange(resp.data))
      .catch(error => console.error(error));
  }

  return (
    <Card className="text-center">
      {hasSurvey && <SuccessCheck />}
      {hasSurvey && <TestSurvey {...diy_survey} />}
      <CardImg top className="welcome-card-img" src={surveyImg} />
      <CardBody className="d-flex flex-column">
        {noSurvey &&
          <CardTitle tag="h5" className={classNames({ 'text-danger': !hasUrl })}>
            {hasUrl ? 'DIY' : 'Contact us to learn more about our DIY solution'}
          </CardTitle>
        }
        <CardText tag="div">
          {noSurvey ? (
            <p>Using our platform, quickly create a DIY survey following one of the predefined templates</p>
          ) : (
            <SurveyInfo {...diy_survey} />
          )}
        </CardText>
        {noSurvey ? (
          <CardButton>
            {hasUrl ? (
              <DiySurveyStartLink hash={hash} diy_url={diy_url} />
            ) : (
              <Button outline color="danger">Add to LAB</Button>
            )}
          </CardButton>
        ) : (
          <CardButton>
            <DiySurveyStartLink hash={hash} diy_url={diy_url} label="RESTART" />
            <Button outline color="warning" onClick={onRemoveSurvey}>Remove</Button>
          </CardButton>
        )}
      </CardBody>
    </Card>
  );
}
