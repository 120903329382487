import React, { Component } from 'react';
import { HeadCell } from './HeadCell';
import PropTypes from 'prop-types';

export class Head extends Component {
  static propTypes = {
    columns: PropTypes.object.isRequired,
    onSort: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="d-flex flex-row flex-nowrap grid-row header">
        {
          Object
            .keys(this.props.columns)
            .map((columnIndex) =>
              <HeadCell
                key={columnIndex}
                column={this.props.columns[columnIndex]}
                onSort={this.props.onSort}
              />
          )
        }
      </div>
    );
  }
}
