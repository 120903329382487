import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { client } from '../Client';

export default function FeasibilityCountrySelect({ value, onChange, ...props }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    client.api('GET', '/feasibility/country')
      .then(resp => setOptions(Object.keys(resp.data).map(value => ({ value, label: resp.data[value] }))))
      .catch(error => console.error(error));
  }, []);

  function handleChange(option) {
    onChange({ [props.name]: option.value });
  }

  return (
    <Select
      {...props}
      options={options}
      isClearable={false}
      onChange={handleChange}
      value={options.filter(option => option.value === value)}
    />
  );
}
