import React, { useContext, useEffect } from 'react';
import BaseScreen from './BaseScreen';
import UserContext from '../UserContext';
import { client } from '../Client';

export default function SurveyScreen() {
  const { onTokenChange } = useContext(UserContext);

  useEffect(() => {
    client.api('GET', '/diy/survey')
      .then(resp => onTokenChange(resp.data))
      .catch(error => console.error(error));
  }, []);

  return (
    <BaseScreen />
  );
}
