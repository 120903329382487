import React, { useState, useContext } from 'react';
import {
  Row, Col, Modal, ModalHeader, ModalBody, Button
} from 'reactstrap';
import CheckoutContext from '../CheckoutContext';
import { TermsContextProvider } from './TermsContext';
import SampleMarketModal from './SampleMarketModal';
import TermsAndConditions from './TermsAndConditions';
import SampleMarket from './SampleMarket';
import LoadingModal from './LoadingModal';
import useModal from './useModal';
import useMarket from './useMarket';
import { client } from '../Client';
import { showError } from '../notify';

export default function SamplingModal({ isOpen, toggle }) {
  const { checkout: { sampling = {} }, updateCheckout } = useContext(CheckoutContext);
  const [showMarketModal, toggleMarketModal] = useModal();
  const [showTermsModal, toggleTermsModal] = useModal();
  const [isCalculateModalOpen,, showCalculateModal] = useModal();
  const [openMarket, setOpenMarket] = useState(null);
  const [saveMarket, removeMarket, updateMarketResult] = useMarket();
  const sampleCount = Object.keys(sampling).length;

  function toggleMarket(key) {
    setOpenMarket(openMarket === key ? null : key);
  }

  async function calculateMarketFeasibility(market) {
    const changed = { ...market };
    let fault = 0;
    try {
      const resp = await client.api('POST', '/feasibility/feasible', market);
      Object.assign(changed, { result: resp.data });
    } catch (error) {
      fault = 1;
      showError(error);
    }

    return [changed.key, changed, fault];
  }

  async function finalizeMarkets() {
    showCalculateModal();

    const results = await Promise.all(Object.values(sampling).map(calculateMarketFeasibility));

    updateCheckout({ sampling: Object.fromEntries(results) });

    showCalculateModal(false);

    if (results.reduce((acc, [,,fault]) => (acc + fault), 0) === 0) {
      toggle();
    }
  }

  function addNewMarket(market) {
    saveMarket(market)
    setOpenMarket(market.key);
  }

  function calculateFeasibility(market, updateFeasibilityResult) {
    showCalculateModal();

    client.api('POST', '/feasibility/feasible', market)
      .then(resp => {
        showCalculateModal(false);
        updateFeasibilityResult(resp.data);
      })
      .catch(error => {
        showCalculateModal(false);
        showError(error);
      });
  }

  return (
    <TermsContextProvider>
      <Modal isOpen={isOpen} backdrop="static" keyboard={false} size="xl" >
        <ModalHeader toggle={toggle} />
        <ModalBody>
          {Object.entries(sampling).map(([key, market]) =>
            <Row key={key}>
              <Col>
                <SampleMarket
                  market={market}
                  onDelete={() => removeMarket(market)}
                  onChange={saveMarket}
                  calculateFeasibility={calculateFeasibility}
                  isOpen={!market.locked && openMarket === market.key}
                  toggle={() => toggleMarket(market.key)}
                  toggleTerms={toggleTermsModal}
                />
              </Col>
            </Row>
          )}
          <Row className="text-center">
            <Col>
              <Button className="mt-3" color="success" size="lg" onClick={toggleMarketModal}>
                Add target group
              </Button>
              {sampleCount > 0 &&
                <Button outline onClick={finalizeMarkets} size="lg" color="warning" className="mt-3 float-right">Finalize</Button>
              }
            </Col>
          </Row>
          {sampleCount === 0 &&
            <Row className="text-center text-info my-5">
              <Col>
                Please add target group to start....
              </Col>
            </Row>
          }
        </ModalBody>
      </Modal>
      <SampleMarketModal
        isOpen={showMarketModal}
        toggle={toggleMarketModal}
        onSave={addNewMarket}
      />
      <TermsAndConditions isOpen={showTermsModal} toggle={toggleTermsModal} />
      <LoadingModal isOpen={isCalculateModalOpen} loadingText="Calculating feasibility ..." />
    </TermsContextProvider>
  );
}
