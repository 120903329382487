import React, { useState, useEffect, useRef, useContext } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Card, CardHeader, CardBody, Form, Table, ButtonGroup, ListGroup, ListGroupItem,
  Button, Row, Col, FormGroup, Label, InputGroup, InputGroupText, InputGroupAddon,
  Input, Badge, Alert
} from 'reactstrap';
import Price from './Price';
import SampleMarketTextSummary from './SampleMarketTextSummary';
import { TermsContext } from './TermsContext';
import { client } from '../Client';
import { showError } from '../notify';

function isSelected(val, option) {
  return Array.isArray(option) ? option.includes(val) : val === option;
}

function selectedColor(val, option) {
  return isSelected(val, option) ? 'warning' : 'secondary';
}

function showQuota(obj) {
  return obj.type === 'quota';
}

function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function QuotaButton({ name, title, value, type, onChange }) {
  return (
    <Button
      type="button" name={name} value={value}
      color={selectedColor(type, value)}
      className="text-nowrap" onClick={() => onChange(value)}
    >
      {title}
    </Button>
  );
}

function QuotaTypeButtons(props) {
  return (
    <ButtonGroup>
      <QuotaButton name="type" value="nat" title="No quotas" {...props} />
      <QuotaButton name="type" value="quota" title="Quotas +" {...props} />
    </ButtonGroup>
  );
}

function QuotaRow(props) {
  return (
    <tr>
      <td></td>
      <td>
        <Quota {...props} />
      </td>
      <td></td>
      <td></td>
    </tr>
  );
}

function QuotaHeaderRow({ label = '', totalPercentage = 0, isAge = false }) {
  return (
    <Row className="border-bottom mb-2">
      <Col>
        {isAge ? (
          <InputGroup size="sm">
            <Input plaintext value="From:" readOnly />
            <InputGroupAddon addonType="prepend" className="invisible">-</InputGroupAddon>
            <Input plaintext value="To:" readOnly />
          </InputGroup>
        ) : (
          <Input plaintext value={label} readOnly />
        )}
      </Col>
      <Col className="text-center">
        <span className={Number(totalPercentage.toFixed(2)) === 100 ? 'text-success' : 'text-danger'}>
          {totalPercentage.toFixed(2)}%
        </span>
      </Col>
      <Col className="text-center">
        <Row>
          <Col><Input plaintext value="Requested" readOnly /></Col>
          <Col><Input plaintext value="Feasible" readOnly /></Col>
        </Row>
      </Col>
      {isAge &&
        <Col xs="auto">
          <Button type="button" color="danger" size="sm" className="invisible">
            <i className="fa fa-minus" aria-hidden="true" />
          </Button>
        </Col>
      }
    </Row>
  );
}

function Quota({ completes = 0, quota = {}, feasible = {}, values = {}, isAge = false, label = '', onChange}) {
  const obj = isAge ? quota : values;
  const totalPercentage = Object.values(quota).reduce((acc, curr) => acc + Number(curr), 0);

  function updateQuota(key, value) {
    onChange({ ...quota, [key]: value });
  }

  function replaceQuota(oldKey, newKey) {
    const changes = Object.fromEntries(
      Object.entries(quota).map(([key, value]) => key === oldKey ? [newKey, value] : [key, value])
    )

    onChange(changes);
  }

  function removeQuota(key) {
    const changes = { ...quota };
    delete changes[key];

    onChange(changes);
  }

  return (
    <ListGroup>
      {Object.keys(obj).map((key, idx) => {
        const [min, max] = key.split('-');
        const hasQuota = completes && quota[key];
        const needCompletes = Math.round(completes * quota[key] / 100);

        return (
          <ListGroupItem key={idx}>
            {idx === 0 &&
              <QuotaHeaderRow isAge={isAge} label={label} totalPercentage={totalPercentage} />
            }
            <Row>
              <Col>
                {isAge ? (
                  <InputGroup size="sm">
                    <Input
                      type="number" min="14" max="99" size="2"
                      value={min} onChange={e => replaceQuota(key, `${e.target.value}-${max}`)}
                    />
                    <InputGroupAddon addonType="prepend">-</InputGroupAddon>
                    <Input
                      type="number" min="14" max="99" size="2"
                      value={max} onChange={e => replaceQuota(key, `${min}-${e.target.value}`)}
                    />
                  </InputGroup>
                ) : (
                  <span>{obj[key]}</span>
                )}
              </Col>
              <Col>
                <InputGroup size="sm">
                  <Input
                    type="number" min="1" max="100" size="3"
                    step={0.01}
                    value={quota[key] || ''}
                    onChange={e => updateQuota(key, e.target.value)}
                  />
                  <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col className="text-center">
                <Row>
                  <Col>{hasQuota ? needCompletes : '-'}</Col>
                  <Col>
                    {feasible[key] === undefined ? (
                      <span>?</span>
                    ) : (
                      <span className={hasQuota && needCompletes > feasible[key] ? 'text-danger' : 'text-success'}>
                        {feasible[key]}
                      </span>
                    )}
                  </Col>
                </Row>
              </Col>
              {isAge &&
                <Col xs="auto">
                  <Button type="button" color="danger" size="sm" onClick={() => removeQuota(key)}>
                    <i className="fa fa-minus" aria-hidden="true" />
                  </Button>
                </Col>
              }
            </Row>
          </ListGroupItem>
        );
      })}
      {isAge &&
        <ListGroupItem>
          <Button type="button" color="success" size="sm" onClick={() => updateQuota('-', '')}>
            <i className="fa fa-plus" aria-hidden="true" />{' '}Add
          </Button>
        </ListGroupItem>
      }
    </ListGroup>
  );
}

function AddProfiling({ attributes, onAdd }) {
  const [selected, setSelected] = useState('');

  return (
    <React.Fragment>
      <Input
        type="select" className="mr-3" size="sm"
        value={selected} style={{ width: 200, display: 'inline' }}
        onChange={e => setSelected(e.target.value)}
      >
        <option value="">-- select --</option>
        {Object.keys(attributes).map(key =>
          <option key={key} value={key}>{attributes[key]}</option>
        )}
      </Input>
      <Button type="button" outline size="sm" disabled={selected === ''} onClick={() => onAdd(selected)}>
        Add {' '}
        <i className="fa fa-plus" aria-hidden="true" />
      </Button>
    </React.Fragment>
  );
}

function ButtonSelect({ options, onChange, value = [], multiSelect = true, customHandlers = {} }) {
  
  function onSelect(event) {
    const { value: selected } = event.target;
    let values = [];
    if (multiSelect) {
      if (value.includes(selected)) {
        values = value.filter(val => val !== selected);
      } else {
        values = [...value, selected];
      }
    } else {
      values.push(selected);
    }

    onChange(values);
  }
  
  return (
    <ButtonGroup>
      {Object.keys(options).map(key =>
        <Button
          key={key} color={selectedColor(key, value)}
          value={key} type="button"
          onClick={typeof customHandlers[key] === 'function' ? customHandlers[key] : onSelect}
        >
          {options[key]}
        </Button>
      )}
    </ButtonGroup>
  );
}

function Sample({ value, completes, updateValues, attributes = {}, regions, feasibility = {} }) {
  const { age, gender, region, device = {}, ...sample } = value;
  const [ageFrom = '', ageTo = ''] = age.values || [];
  const [regio] = region.values || [];

  function updateProperty(key, prop, val) {
    const sample = cloneObject(value);
    if (!sample[key]) {
      sample[key] = {};
    }
    sample[key][prop] = val;
    // reset quota
    if (prop === 'type' && val === 'nat') {
      sample[key].quota = {};
      // reset values for region
      if (key === 'region') {
        sample[key].values = [];
      }
    }
    // if only one value is selected
    if (key === 'gender' && prop === 'values' && val.length !== 2) {
      sample[key].quota = {};
      sample[key].type = 'nat';
    }
    // reset quota if value changed
    if (key === 'region' && prop === 'values') {
      sample[key].quota = Object.assign({}, regions[val]);
    }

    updateValues({ sample });
  }

  function removeProperty(key) {
    const sample = cloneObject(value);
    delete sample[key];

    updateValues({ sample });
  }

  function CustomRegionMessage() {
    return (
      <tr>
        <td></td>
        <td>
          <Alert color="warning">
            Custom regional quotas can be discussed with the JTN project manager after the project is created
          </Alert>
        </td>
        <td></td>
        <td></td>
      </tr>
    );
  }

  return (
    <Table borderless>
      <tbody>
        <tr>
          <td className="align-middle font-weight-bold">Age</td>
          <td>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>From:</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number" min="14" max="99"
                value={ageFrom}
                onChange={e => updateProperty('age', 'values', [e.target.value, ageTo])}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>To:</InputGroupText>
              </InputGroupAddon>
              <Input
                type="number" min="14" max="99"
                value={ageTo}
                onChange={e => updateProperty('age', 'values', [ageFrom, e.target.value])}
              />
            </InputGroup>
          </td>
          <td></td>
          <td>
            <QuotaTypeButtons type={age.type} onChange={val => updateProperty('age', 'type', val)} />
          </td>
        </tr>
        {showQuota(age) &&
          <QuotaRow
            quota={age.quota} feasible={feasibility.age} completes={completes} isAge
            onChange={val => updateProperty('age', 'quota', val)}
          />
        }
        <tr>
          <td><span className="align-middle font-weight-bold">Gender</span></td>
          <td>
            <ButtonSelect
              options={{ m: 'Male', f: 'Female' }}
              value={gender.values}
              onChange={val => updateProperty('gender', 'values', val)}
            />
          </td>
          <td></td>
          <td>
            {(gender.values || []).length === 2 &&
              <QuotaTypeButtons
                type={gender.type}
                onChange={val => updateProperty('gender', 'type', val)}
              />
            }
          </td>
        </tr>
        {showQuota(gender) &&
          <QuotaRow
            quota={gender.quota} feasible={feasibility.gender} completes={completes}
            values={{ m: 'Male', f: 'Female' }} label="Gender"
            onChange={val => updateProperty('gender', 'quota', val)}
          />
        }
        <tr>
          <td><span className="align-middle font-weight-bold">Region</span></td>
          <td>
            <QuotaTypeButtons type={region.type} onChange={val => updateProperty('region', 'type', val)} />
            &nbsp;
            {showQuota(region) &&
              <ButtonSelect
                options={{ region1: 'Level 1', region2: 'Level 2', custom: 'Custom' }}
                multiSelect={false}
                value={region.values}
                onChange={val => updateProperty('region', 'values', val)}
              />
            }
          </td>
          <td></td>
          <td></td>
        </tr>
        {regio === 'custom' && <CustomRegionMessage />}
        {regio !== undefined && regio !== 'custom' &&
          <QuotaRow
            quota={region.quota} feasible={feasibility.region} completes={completes}
            values={Object.fromEntries(Object.keys(regions[regio] || {}).map(val => [val, val]))}
            onChange={val => updateProperty('region', 'quota', val)} label="Region"
          />
        }
        <tr>
          <td><span className="align-middle font-weight-bold">Devices</span></td>
          <td>
            <ButtonSelect
              options={{ pc: 'PC', tablet: 'Tablet', mobile: 'Mobile' }}
              value={device.values}
              onChange={val => updateProperty('device', 'values', val)}
            />
          </td>
          <td></td>
          <td></td>
        </tr>
        {Object.keys(sample).map(key =>
          <tr key={key}>
            <td><span className="align-middle font-weight-bold">{attributes[key]}</span></td>
            <td>
              <ButtonSelect
                options={{ Yes: 'Yes', No: 'No'}}
                multiSelect={false}
                value={sample[key].values}
                onChange={val => updateProperty(key, 'values', val)}
              />
            </td>
            <td></td>
            <td>
              <Button type="button" color="danger" onClick={() => removeProperty(key)}>
                <i className="fa fa-minus" aria-hidden="true" />
              </Button>
            </td>
          </tr>
        )}
        {Object.keys(attributes).length > 0 &&
          <tr>
            <td colSpan="4">
              <AddProfiling attributes={attributes} onAdd={key => updateProperty(key, 'values', [])} />
            </td>
          </tr>
        }
      </tbody>
    </Table>
  );
}

function calculateTotalCosts(totalCost) {
  return totalCost || '-';
}

function SimpleView({ completes, ir, loi, feasibility = {} }) {
  return (
    <CardBody>
      <Row className="text-center">
        <Col>Completes</Col>
        <Col>Feasible</Col>
        <Col>IR</Col>
        <Col>LOI</Col>
        <Col>CPI</Col>
        <Col>Total Costs</Col>
      </Row>
      <Row className="text-center">
        <Col>{completes}</Col>
        <Col>{feasibility.feasible || '-'}</Col>
        <Col>{ir} %</Col>
        <Col>{loi} min</Col>
        <Col>{feasibility.cpi || '-'}</Col>
        <Col>{calculateTotalCosts(feasibility.totalCost)}</Col>
      </Row>
    </CardBody>
  );
}

function FormView({ values, updateValues, info, regions, calculateFeasibility, toggleTerms, onPrint, printing, feasibility = {} }) {
  const { terms } = useContext(TermsContext);
  const {attributes} = info;

  function onValueChange(event) {
    const { name, value } = event.target;

    updateValues({ [name]: value });
  }

  function showMefMessage({mef, totalCost}) {
    if (mef) {
      return <div>The costs for the target group fall under the Minimum Engagement Fee of {totalCost} EUR.</div>;
    }
    return;
  }

  return (
    <React.Fragment>
      <CardBody>
        <Row form>
          <Col sm={{ size: 2, offset: 2 }}>
            <FormGroup className="text-center">
              <Label for="completes">Completes</Label>
              <Input
                type="number" name="completes" min="1"
                value={values.completes}
                onChange={onValueChange}
                required
              />
            </FormGroup>
          </Col>
          <Col sm={{ size: 2, offset: 1 }}>
            <FormGroup className="text-center">
              <Label for="ir">Incidence Rate</Label>
              <InputGroup>
                <Input
                  type="number" name="ir" min="1" max="100"
                  value={values.ir}
                  onChange={onValueChange}
                  required
                />
                <InputGroupAddon addonType="append">%</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col sm={{ size: 2, offset: 1 }}>
            <FormGroup className="text-center">
              <Label for="loi">Length of Interview</Label>
              <InputGroup>
                <Input
                  type="number" name="loi" min="1" max="45"
                  value={values.loi}
                  onChange={onValueChange}
                  required
                />
                <InputGroupAddon addonType="append">min</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Sample
              value={values.sample}
              feasibility={feasibility}
              updateValues={updateValues}
              attributes={attributes}
              completes={values.completes}
              regions={regions}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            <FormGroup>
              <Label for="notes">Additional Notes</Label>
              <Input
                type="textarea" name="notes"
                value={values.notes} rows="4"
                onChange={onValueChange}
              />
            </FormGroup>
          </Col>
          <Col sm="4">
            <ListGroup className="mb-3" style={{ width: '100%' }}>
              <ListGroupItem>
                <span className="font-weight-bold">Requested</span>
                <Badge className="pull-right" pill>{values.completes || 0}</Badge>
              </ListGroupItem>
              <ListGroupItem>
                <span className="font-weight-bold">Feasible</span>
                <Badge className="pull-right" pill>{feasibility.feasible || '?'}</Badge>
              </ListGroupItem>
              {feasibility.feasible > 0 &&
                <ListGroupItem>
                  <span className="font-weight-bold">CPI</span>
                  <Price
                    className="pull-right badge badge-secondary badge-pill" value={feasibility.cpi || '?'}
                  />
                </ListGroupItem>
              }
              {feasibility.feasible > 0 &&
                <ListGroupItem>
                  <span className="font-weight-bold">Total Costs</span>
                  <Price
                    className="pull-right badge badge-secondary badge-pill"
                    value={calculateTotalCosts(feasibility.totalCost)}
                  />
                  {showMefMessage(feasibility)}
                </ListGroupItem>
              }
            </ListGroup>
          </Col>
        </Row>
        <Row className="text-right">
          <Col xs="auto">
            <SampleMarketTextSummary market={values} info={info} />
          </Col>
          {!printing &&
            <Col>
              <Button outline type="button" className="ml-2" onClick={onPrint}>
                <i className="fa fa-print" />
              </Button>
              <Button type="button" className="ml-2" onClick={() => calculateFeasibility(values)}>
                Calculate feasibility
              </Button>
            </Col>
          }
        </Row>
        {printing ? (
          <Row className="mt-3" style={{ display: "block"}}>
            <div className="pagebreak" />
            <Col>
              <pre className="border-0" style={{ whiteSpace: 'pre-wrap' }}>
                <h3 className="text-center">Terms and Conditions</h3>
                {terms}
              </pre>
            </Col>
          </Row>
        ) : (
          <Row className="text-right">
            <Col>
              <Button type="button" color="link" onClick={toggleTerms}>
                Terms and Conditions
              </Button>
            </Col>
          </Row>
        )}
      </CardBody>
    </React.Fragment>
  );
}

export default function SampleMarket({ market, onDelete, onChange, calculateFeasibility, isOpen, toggle, toggleTerms }) {
  const componentRef = useRef(null);
  const [info, setInfo] = useState({});
  const [printing, setPrinting] = useState(false);
  const [feasibility, setFeasibility] = useState(market.result || {});
  const [regions, setRegions] = useState({ region1: {}, region2: {} });

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleAfterPrint = React.useCallback(() => {
    setPrinting(false);
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    onAfterPrint: handleAfterPrint,
    documentTitle: info.title || market.country,
  });

  useEffect(() => {
    client.api('GET', `/feasibility/${market.country}/attributes`)
      .then(resp => setInfo(resp.data))
      .catch(showError);
  }, [market.country]);

  useEffect(() => {
    client.api('GET', `/feasibility/${market.country}/regions`)
      .then(resp => setRegions(resp.data))
      .catch(showError);
  }, [market.country]);

  useEffect(() => {
    client.api('POST', '/feasibility/feasible', market)
      .then(resp => setFeasibility(resp.data))
      .catch(error => console.error(error));
  }, [market]);

  useEffect(() => {
    if (printing) {
      handlePrint();
    }
  }, [printing]);

  function printSample() {
    setPrinting(true);
  }

  function cancelSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  function updateValues(newValues) {
    const {result, ...oldValues} = market;
    setFeasibility({});
    onChange({ ...oldValues, ...newValues });
  }

  return (
    <Card className="mt-3" innerRef={componentRef}>
      <CardHeader className="font-weight-bold align-middle">
        {!market.locked && !printing &&
          <i
            className={`fa fa-${isOpen ? 'minus' : 'plus'}-square mr-4 pointer-cursor`}
            aria-hidden="true"
            onClick={toggle}
          />
        }
        {info.title || market.country}
        {market.locked &&
          <i className="fa fa-lock fa-lg float-right text-warning" aria-hidden="true" />
        }
        {!market.locked && !printing &&
          <Button className="float-right" color="danger" size="sm" onClick={onDelete} >
            <i className="fa fa-trash" aria-hidden="true" />
          </Button>
        }
      </CardHeader>
      {isOpen ? (
        <Form onSubmit={cancelSubmit} autoComplete="off">
          <FormView
            values={market}
            feasibility={feasibility}
            updateValues={updateValues}
            info={info}
            regions={regions}
            calculateFeasibility={calculateFeasibility}
            toggleTerms={toggleTerms}
            printing={printing}
            onPrint={printSample}
          />
        </Form>
      ) : (
        <SimpleView {...market} feasibility={feasibility} />
      )}
    </Card>
  );
}
