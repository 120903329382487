import React from 'react';
import MediaQuery from 'react-responsive';

function Desktop(props) {
  return (
    <MediaQuery {...props} minWidth={992} />
  );
}

function Tablet(props) {
  return (
    <MediaQuery {...props} minWidth={768} maxWidth={991} />
  );
}

function Mobile(props) {
  return (
    <MediaQuery {...props} maxWidth={767} />
  );
}

export {
  Desktop,
  Tablet,
  Mobile
};
