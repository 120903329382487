import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ToastContainer, Bounce } from 'react-toastify';
import { RouterContext } from './RouterContext';
import UserProvider from '../UserProvider';
import CheckoutProvider from '../CheckoutProvider';

import { Header } from './Header';
import Footer from './Footer';
import RouteNotFound from './RouteNotFound';
import ToastCloseButton from './ToastCloseButton';
import routes from './routes';

export default function App({ history, location, match }) {
  return (
    <RouterContext.Provider value={{ history, location, match }}>
      <UserProvider>
        <Header />

        <main role="main" className="flex-shrink-0">
          <Container fluid>
            <ToastContainer
              position="top-center" draggable={false}
              transition={Bounce} closeButton={<ToastCloseButton />}
            />
            <CheckoutProvider>
              <Switch>
                {routes.map((route, idx) =>
                  <Route exact key={`route${idx}`} path={route.path} name={route.name} component={route.component} />
                )}
                <Route component={RouteNotFound} />
              </Switch>
            </CheckoutProvider>
          </Container>
        </main>

        <Footer />
      </UserProvider>
    </RouterContext.Provider>
  );
}
