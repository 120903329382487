import React from 'react';
import { Table } from 'reactstrap';

const statuses = {
  total: 'TOTAL',
  complete: 'C',
  screenout: 'SO',
  quotafull: 'QF',
  incomplete: 'I',
  duplicate: 'DU'
};

function calculatePercent(num, total) {
  return total ? `${((num * 100) / total).toFixed(2)} %` : '0 %';
}

export default function ProjectProgressTable({ data }) {
  return (
    <Table size="sm" hover>
      <thead>
        <tr>
          <th>Date</th>
          {Object.values(statuses).map(status =>
            <th key={status}>{status}</th>
          )}
          <th>INCID</th>
          <th>INCQF</th>
          <th>CONV</th>
          <th>DOR</th>
          <th>DUPL</th>
          <th>LOI</th>
        </tr>
      </thead>
      <tbody>
        {data.map(row =>
          <tr key={row.created}>
            <th scope="row">{row.created}</th>
            {Object.keys(statuses).map(status =>
              <td key={status}>{row[status]}</td>
            )}
            <td>{calculatePercent(row.complete, row.complete + row.screenout)}</td>
            <td>{calculatePercent(row.complete, row.complete + row.screenout + row.quotafull)}</td>
            <td>{calculatePercent(row.complete, row.total)}</td>
            <td>{calculatePercent(row.incomplete, row.total)}</td>
            <td>{calculatePercent(row.duplicate, row.total)}</td>
            <td>{row.loi} min</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
