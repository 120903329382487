import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import UserContext from '../UserContext';

export default function NavRightItems(props) {
  const { user: { first_name, last_name }} = useContext(UserContext);

  return (
    <Nav navbar className="ml-auto">
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <i className="fa fa-user" aria-hidden="true" /> {first_name} {last_name}
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>
            <Link className="nav-link" to="/logout">
              <i className="fa fa-lock" /> Logout
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
}
