import React, { useContext } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { TermsContext } from './TermsContext';

export default function TermsAndConditions({ isOpen, toggle }) {
  const { terms } = useContext(TermsContext);

  return (
    <Modal isOpen={isOpen} backdrop="static" keyboard={false} size="lg" >
      <ModalHeader className="p-2" toggle={toggle}>
        Terms and Conditions
      </ModalHeader>
      <ModalBody>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{terms}</pre>
      </ModalBody>
    </Modal>
  );
}
