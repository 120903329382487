import React from 'react';
import { FormGroup, InputGroup, Input, FormFeedback } from 'reactstrap';

export default function FormLoginRow({ cssClass, icon, errors, ...props }) {
  const hasError = errors[props.name] !== undefined;

  return (
    <FormGroup className={cssClass}>
      <InputGroup>
        <div className="input-group-prepend">
          <span className="input-group-text">{icon}</span>
        </div>
        <Input {...props} invalid={hasError} />
        {hasError &&
          <FormFeedback>{errors[props.name]}</FormFeedback>
        }
      </InputGroup>
    </FormGroup>
  );
}
