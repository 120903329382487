import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {
  Row, Col, Card, CardBody, InputGroup, Badge,
  Input, InputGroupAddon, Button, Form, UncontrolledTooltip
} from 'reactstrap';
import classNames from 'classnames';
import LoadingModal from './LoadingModal';
import Price from './Price';
import ProjectProgress from './ProjectProgress';
import ProjectStatus from './ProjectStatus';
import DateFormatter from '../DateFormatter';
import { client } from '../Client';
import { showError } from '../notify';

export default function BaseScreen() {
  const { id } = useParams();
  const [updating, setUpdating] = useState(false);
  const [quota, setQuota] = useState(0);
  const [project, setProject] = useState(null);

  useEffect(() => {
    client.api('GET', `/project/${id}`)
      .then(resp => updateState(resp.data))
      .catch(showError);
  }, [id]);

  function updateState(project) {
    setQuota(project.requiredCompletes);
    setProject(project);
  }

  function updateQuota(event) {
    event.preventDefault();
    setUpdating(true);

    client.api('PUT', `/project/${id}/quota`, { quota })
      .then(resp => {
        updateState(resp.data);
        setUpdating(false);
      })
      .catch(error => {
        setUpdating(false);
        showError(error);
      });
  }

  return project === null ? ( 
    <LoadingModal isOpen />
  ) : (
    <React.Fragment>
      <LoadingModal isOpen={updating} loadingText="Updating quota ..." />
      <Row className="mt-4">
        <Col sm="12" md={{ size: 10, offset: 1 }}>
          <Row>
            <Col><h4>{project.projectName}</h4></Col>
          </Row>
          <Row>
            <Col>Creator: {project.creator}</Col>
          </Row>
          <Row>
            <Col>{project.clientName}</Col>
          </Row>
          <Row className="mt-5">
            <Col className="font-weight-bold">Project Information</Col>
            <Col className="text-right">
              Start date: <DateFormatter data={project.created} />
            </Col>
          </Row>
          <Row className="text-center pt-2">
            <Col>Country</Col>
            <Col>Completes</Col>
            <Col>Incidence Rate</Col>
            <Col>Length of Interview</Col>
            <Col>Initial CPI</Col>
            <Col>Current CPI</Col>
          </Row>
          <Row className="text-center border-bottom pb-1">
            <Col className="d-flex justify-content-center align-items-center">
              {project.countryName || project.country}
            </Col>
            <Col>
              <Form onSubmit={updateQuota}>
                <InputGroup size="sm">
                  <Input
                    type="number" value={quota}
                    min={project ? project.completes : 1}
                    step="1" required
                    onChange={e => setQuota(e.target.value)} />
                  <InputGroupAddon addonType="append">
                    <Button type="submit" outline disabled={updating}>Update</Button>
                  </InputGroupAddon>
                </InputGroup>
                </Form>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              {project.incidenceRate} %
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              {project.lengthOfInterview} min
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <Price value={project.commissionedCPI} />
            </Col>
            <Col className={classNames('d-flex', 'justify-content-center', 'align-items-center', {'text-success': project.currentCPI < project.commissionedCPI, 'text-warning': project.currentCPI > project.commissionedCPI})}>
              <Price value={project.currentCPI} />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="font-weight-bold">Live Metrics</Col>
          </Row>
          <Row>
            <Col className="px-0">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2" className="align-self-center font-weight-bold">
                      {project.countryName || project.country}
                    </Col>
                    <Col md="10">
                      <Row className="text-center font-weight-bold">
                        <Col>Status</Col>
                        <Col>Completes</Col>
                        <Col>Incidence Rate</Col>
                        <Col>Length of Interview</Col>
                        <Col>Dropout Rate</Col>
                        <Col>Current costs</Col>
                      </Row>
                      <Row className="text-center">
                        <Col>
                          <ProjectStatus {...project} />
                        </Col>
                        <Col>{project.completes} / {project.requiredCompletes}</Col>
                        <Col className={classNames({
                          'text-danger': project.ir < 0.8 * project.incidenceRate,
                          'text-success': project.ir > 1.2 * project.incidenceRate,
                        })}>
                          {project.ir} %
                        </Col>
                        <Col className={project.loi > project.lengthOfInterview + 5 ? 'text-danger' : 'text-success'}>
                          {project.loi_formatted} min
                        </Col>
                        <Col className={classNames({
                          'text-warning': project.dor > 15,
                          'text-danger': project.dor > 30 
                        })}>
                          {project.dor} %
                        </Col>
                        <Col>
                          <Price value={project.commissionedCPI * project.completes} />
                          {project.use_mef && project.commissionedCPI * project.completes < project.mef &&
                            <React.Fragment>
                              <Badge color="warning" pill id="mef-info" className="ml-1">ⓘ</Badge>
                              <UncontrolledTooltip placement="bottom" target="mef-info">
                                Kindly note that JTN has a minimum engagement fee of <Price value={project.mef} />
                              </UncontrolledTooltip>
                            </React.Fragment>
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ProjectProgress projectId={id} />
        </Col>
      </Row>
    </React.Fragment>
  );
}
