import { useState } from 'react';

export default function useModal(defaultState = false) {
  const [isOpen, setShowModal] = useState(defaultState);

  function toggleModal() {
    setShowModal(!isOpen);
  }

  function showModal(show = true) {
    setShowModal(show);
  }

  return [isOpen, toggleModal, showModal];
}
