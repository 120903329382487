import React from 'react';

export default function Footer(props) {
  return (
    <footer className="mt-auto py-3">
      <div className="container text-center">
        <span className="text-muted">
          © Copyright {(new Date()).getFullYear()} <a href="https://jtnresearch.com/" target="_blank" rel="noopener noreferrer">JTN Research</a>
        </span>
      </div>
    </footer>
  );
}
