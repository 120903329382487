import { useContext } from 'react';
import CheckoutContext from '../CheckoutContext';

export default function useMarket() {
  const { checkout: { sampling = {} }, updateCheckout } = useContext(CheckoutContext);

  function saveMarket(market) {
    updateCheckout({ sampling: { ...sampling, [market.key]: market }});
  }

  function removeMarket(market) {
    updateCheckout({
      sampling: Object.fromEntries(
        Object.entries(sampling).filter(([key,]) => key !== market.key)
      )
    });
  }

  return [saveMarket, removeMarket];
}
