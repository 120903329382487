import { Body } from './Body';
import { Head } from './Head';
import { Row } from './Row';
import { Cell } from './Cell';
import { HeadCell } from './HeadCell';
import { NoRecord } from './NoRecord';

export const components = {
  Body,
  Head,
  Row,
  Cell,
  HeadCell,
  NoRecord
};
