import $ from 'jquery';
import jwt_decode from 'jwt-decode';
// import FileSaver from 'file-saver';

const LOCAL_STORAGE_KEY = 'auth-token';

class Client {
  constructor() {
    this.useLocalStorage = (typeof localStorage !== 'undefined');

    let token = '';
    this.authCalled = false;
    this.authHanlder = null;

    if (this.useLocalStorage) {
      token = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (!token) {
        return this.authChecked();
      }

      this.isTokenValid(token)
        .done((response) => {
          if (response && response.success) {
            this.setToken(token);
          }
          this.authChecked();
        })
        .fail((error) => {
          this.authChecked();
        });
    }
  }

  authChecked() {
    this.authCalled = true;
    if (typeof this.authHanlder === 'function') {
      this.authHanlder();
    }
  }

  isAuthenticated() {
    return !!this.token;
  }

  getUser() {
    return this.user;
  }

  setToken(token) {
    this.token = token;
    this.user = jwt_decode(token);

    if (this.useLocalStorage) {
      localStorage.setItem(LOCAL_STORAGE_KEY, token);
    }
  }

  removeToken() {
    this.token = null;
    this.user = null;

    if (this.useLocalStorage) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }

  isTokenValid(token) {
    // See note about tokens above
    return this.api('GET', '/auth/validate', undefined, true, token);
  }

  addAuthenticationListener(callback) {
    if (this.authCalled) {
      setImmediate(callback);
    }

    this.authHanlder = callback;
  }

  login(data) {
    return this.api('POST', '/auth/login', data).then((response) => {
      this.setToken(response.data);
    });
  }

  logout() {
    this.removeToken();
  }

  loadGridData(path, params) {
    return this.api('GET', path, params);
  }

  api(method, path, data, processData = true, token, extraOptions = {}) {
    if (!window.apiBaseUrl) {
      throw new Error('Define URL for API Client as a global variable (window.apiBaseUrl)');
    }

    const url = `${window.apiBaseUrl}${path}`;
    const headers = { accept: 'application/json' };

    if (token || this.token) {
      headers.Authorization = `Bearer ${token || this.token}`;
    }

    const options = {
      method,
      url,
      headers,
      processData,
      data,
      ...extraOptions
    };

    if (!processData) {
      options.contentType = false;
    }

    return $.ajax(options);
  }
/*
  fileDownload(path, name) {
    return this
      .api('GET', path, null, false, null, { dataType: 'binary', responseType: 'arraybuffer' })
      .done((fileContent, status, xhr) => {
        const disposition = xhr.getResponseHeader('Content-Disposition');
        const fileName = name || decodeURIComponent(disposition.substr(disposition.indexOf('=') + 1));
        FileSaver.saveAs(new File([fileContent], fileName));
      });
  }
*/
}

export const client = new Client();
