import React, { useContext } from 'react';
import {
  CardImg, CardTitle, CardText, Card, CardBody, Button 
} from 'reactstrap';
import sampleImg from './sample.png';
import SamplingModal from './SamplingModal';
import useModal from './useModal';
import CardButton from './CardButton';
import SuccessCheck from './SuccessCheck';
import CheckoutContext from '../CheckoutContext';

export default function SamplingCard() {
  const { checkout: { sampling = {} }, updateCheckout } = useContext(CheckoutContext);
  const [showModal, toggleModal] = useModal();
  const samplesCount = Object.keys(sampling).length;

  return (
    <React.Fragment>
      <Card className="text-center">
        {samplesCount > 0 && <SuccessCheck />}
        <CardImg top className="welcome-card-img" src={sampleImg} alt="Engage JTN Panel" />
        <CardBody className="d-flex flex-column">
          <CardTitle tag="h5">Engage JTN Panel</CardTitle>
          <CardText tag="div">
            Purchase our high quality double opt-in panel asset / check prices and feasibility
          </CardText>
          {samplesCount === 0 ? (
            <CardButton>
              <Button outline onClick={toggleModal}>START</Button>
            </CardButton>
          ) : (
            <CardButton>
              <Button outline onClick={toggleModal}>Edit</Button>
              <Button outline color="warning" onClick={() => updateCheckout({ sampling: {} })}>Remove</Button>
            </CardButton>
          )}
        </CardBody>
      </Card>
      <SamplingModal isOpen={showModal} toggle={toggleModal} />
    </React.Fragment>
  );
}
