import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, ButtonGroup } from 'reactstrap';
import ProjectGrid from './ProjectGrid';

const projectStatus = ['test', 'live', 'paused', 'closed'];

function FilterButton({ isSelected, ...props }) {
  return (
    <Button
      className="text-capitalize"
      outline={!isSelected}
      color={isSelected ? 'warning' : 'secondary'}
      {...props}
    >
      {props.value}
    </Button>
  );
}

export default function ProjectGridFilter() {
  const grid = useRef(null);
  const [status, setStatus] = useState([]);

  function isSelected(value) {
    return status.includes(value);
  }

  function onStatusClick(event) {
    const { value } = event.target;
    setStatus(
      status.includes(value) ? status.filter(element => element !== value) : status.concat(value)
    );
  }

  function filterGrid() {
    if (grid.current) {
      grid.current.setExtraParams({ status });
    }
  }

  useEffect(filterGrid, [status]);

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col>
          <ButtonGroup size="sm">
            <FilterButton isSelected={!status.length || status.length === 4} value='all' onClick={() => setStatus([])} />
            {projectStatus.map(val =>
              <FilterButton key={val} isSelected={isSelected(val)} value={val} onClick={onStatusClick} />
            )}
          </ButtonGroup>
        </Col>
      </Row>
      <ProjectGrid extraParams={{ status }} ref={grid} />
    </React.Fragment>
  );
}
