import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ComponentContext } from './ComponentContext';

export class Row extends Component {
  static contextType = ComponentContext;

  static propTypes = {
    row: PropTypes.object,
    columns: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    summary: PropTypes.bool,
    rowStyler: PropTypes.any,
    rowCount: PropTypes.number,
    rowIndex: PropTypes.number
  };

  static defaultProps = {
    summary: false
  };

  getRowContent() {
    return Object
      .keys(this.props.columns)
      .map((columnIndex) =>
        <this.context.components.Cell
          key={columnIndex}
          row={this.props.row}
          column={this.props.columns[columnIndex]}
          summary={this.props.summary}
          rowIndex={this.props.rowIndex}
          rowCount={this.props.rowCount}
          currentPage={this.props.currentPage}
          pageSize={this.props.pageSize}
        />
      );
  }

  getBaseClasses() {
    return ['d-flex', 'flex-row', 'flex-nowrap', 'grid-row', 'content', 'rounded'];
  }

  getExtraStyle() {
    if (typeof this.props.rowStyler === 'function') {
      return this.props.rowStyler(this.props.row);
    }

    return;
  }

  getRowClasses() {
    return [this.getBaseClasses(), this.props.className, this.getExtraStyle()];
  }

  getRowClick() {
    return typeof this.props.onClick === 'function' ? () => this.props.onClick(this.props.row) : null;
  }

  render() {
    return (
      <div
        className={classNames(this.getRowClasses())}
        onClick={this.getRowClick()}
      >
        {this.getRowContent()}
      </div>
    );
  }
}
