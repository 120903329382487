import React from 'react';

export default function DateFormatter({ data }) {
  if (!data) {
    return (
      <span>-</span>
    );
  }

  let date;
  if (!(data instanceof Date)) {
    date = new Date(data);
  }

  return (
    <span>
      {date.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}
    </span>
  );
}
