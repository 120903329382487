import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import update from 'immutability-helper';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  CardBody,
  Collapse
} from 'reactstrap';
// import moment from 'moment';
import AlertMessage from '../Message';
import { components } from './components';
import { Pagination } from './Pagination';
import Loader from '../Loader';
import { Desktop, Tablet, Mobile } from '../Responsive';
// import { Excel } from '../Excel';
import { client } from '../Client';
import { ComponentContext } from './ComponentContext';
import { RouterContext } from '../site/RouterContext';

const headerFilter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

const gridState = {};

export default class Grid extends Component {
  static contextType = RouterContext;

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.setDefaultComponents();
  }

  getInitialState(props) {
    return Object.assign({
      data: [],
      currentPage: 1,
      pageSize: props.pageSize || 10,
      recordCount: 0,
      extraParams: props.extraParams || {},
      sortProperties: [],
      message: '',
      success: true,
      modal: false,
      collapse: true,
      selected: [],
      allSelected: false,
      loadingData: null,
      shouldRedirect: false,
      redirect: '/',
      columns: {},
      title: props.title,
      letterFilter: false,
      preload: props.preload === undefined ? true : props.preload
    }, Grid.getGridState(this.gridName, props));
  }

  static propTypes = {
    extraParams: PropTypes.object,
    title: PropTypes.string,
    preload: PropTypes.bool,
    paging: PropTypes.bool,
    storeState: PropTypes.bool
  };

  static defaultProps = {
    paging: true,
    storeState: true
  };

  static getGridState(name, props) {
    if (!props.storeState) {
      return {};
    }

    return gridState[name] || {};
  }

  static setGridState(name, state) {
    gridState[name] = state;
  }

  get gridName() {
    throw new Error('gridName() method should be overwritten');
  }

  setDefaultComponents() {
    this.components = Object.assign({}, components);
  }

  getColumns() {
    throw new Error('getColumns() method should be overwritten');
  }

  /**
   * Fields:
   * - name: string
   * - title: string
   * - dataField: string
   * - sortable: true|false
   * - asc: true|false|null
   * - summarize: true|false
   * - width: int
   * - exactWidth: true|false
   * - align: start, end, center, between, around
   * - renderer: Component
   * - className: string
   * - params: object for renderer
   */
  setColumns(done) {
    const columns = this.convertColumns(this.getColumns());
    this.setState({ columns, sortProperties: this.getSortProperties(columns) }, done);
  }

  convertColumns(cols) {
    const columns = {};
    if (Array.isArray(cols) && cols.length) {
      cols.forEach((column) => {
        if (column.name) {
          const {
            name, title, sortable = true, asc = null, width, renderer, params, align,
            exactWidth = false, dataField = name, className, summarize = false, renderHeader = false
          } = column;

          columns[name] = {
            name, title, sortable, asc, width, renderer, params, align, exactWidth,
            dataField, className, summarize, renderHeader
          };
        }
      });
    }
    return columns;
  }

  componentDidMount() {
    this.setColumns(() => {
      if (this.state.preload) {
        this.loadPageData(this.state.currentPage);
      }
    });
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  }

  getNewButton = (path = this.getBasePath(), title = 'New') => {
    return (
      <Link className="btn btn-success btn-sm" to={`${path}/0`} role="button">
        <i className="fa fa-plus-circle"></i> {title}
      </Link>
    );
  }

  removeMessage = () => {
    this.setState({ message: '', success: true });
  }

  updateRowData = (rowData, message = '') => {
    const idx = this.state.data.findIndex((row) => row.id === rowData.id);
    if (idx !== -1) {
      const data = this.state.data;
      data.splice(idx, 1, rowData);

      this.setState({ data, message });
    }
  }

  isRowSelectable = () => {
    return true;
  }

  onRowClick = (rowData) => {
    if (!this.isRowSelectable(rowData)) {
      return;
    }

    this.onRowSelection(rowData);
  }

  onRowSelection = (rowData) => {
    this.context.history.push(`${this.getBasePath()}/${rowData.id}`);
  }

  isRowChecked(data) {
    return this.state.allSelected || this.state.selected.includes(data);
  }

  rowSelectionChange(event, data) {
    event.stopPropagation();
    const selected = this.state.selected.slice();
    if (selected.includes(data)) {
      selected.splice(selected.indexOf(data), 1);
    } else {
      selected.push(data);
    }

    this.setState({ selected });
  }

  onSelectAll(event) {
    event.stopPropagation();

    this.setState({ allSelected: !this.state.allSelected });
  }

  RowCheckBox = (props) => {
    const { data, className, isHeader } = props;
    const params = isHeader ? {
      name: 'select-all',
      checked: this.state.allSelected,
      onClick: e => this.onSelectAll(e)
    } : {
      name: `select-${data}`,
      checked: this.isRowChecked(data),
      onClick: e => this.rowSelectionChange(e, data)
    };

    return (
      <div className={isHeader ? null : className}>
        <div className="form-check form-check-inline row-checkbox">
          <input 
            className="form-check-input position-static"
            type="checkbox"
            {...params}
            style={{
              marginLeft: 15,
              marginRight: 15
            }}
          />
        </div>
      </div>
    );
  }

  getApiPath = () => {
    throw new Error('getApiPath() method should be overwritten');
  }

  // URL base path
  getBasePath = () => {
    throw new Error('getBasePath() method should be overwritten');
  }

  getExtraParam(param) {
    return this.state.extraParams[param];
  }

  setExtraParam(param, value) {
    this.setExtraParams({ [param]: value });
  }

  setExtraParams(params) {
    const extraParams = Object.assign(
      Object.assign({}, this.state.extraParams),
      params
    );

    this.setState({ extraParams, currentPage: 1 }, this.reload);
  }

  applyExtraParams = (params) => {
    if (this.state.extraParams ) {
      Object.assign(params, { extraParams: this.state.extraParams });
    }
  }

  reload = () => {
    this.loadPageData(this.state.currentPage);
  }

  loadPageData(pageNumber) {
    const { pageSize, extraParams, sortProperties } = this.state;
    const params =  {
      sort: sortProperties
    };

    if (this.props.paging) {
      Object.assign(params, {
        currentPage: pageNumber,
        pageSize
      });
    }

    this.applyExtraParams(params);

    if (this.props.storeState) {
      Grid.setGridState(this.gridName, {
        currentPage: pageNumber,
        pageSize, extraParams, sortProperties,
      });
    }

    this.setState(
      { loadingData: true },
      () => {
        client
          .loadGridData(this.getApiPath(), params)
          .done((response) => this.loadDone(response, pageNumber))
          .fail((error) => this.showError(error, { loadingData: false }));
      }
    );
  }

  clientApi() {
    return client.api.apply(client, arguments);
  }

  loadDone = (response, currentPage) => {
    Object.assign(response, { currentPage, loadingData: false });
    this.setState(response);
  }

  showError = (error, state = {}) => {
    const resp = error.responseJSON ? error.responseJSON : { success: false, message: error.responseText };
    if (!resp.message) {
      resp.message = 'An error occurred';
    }
    
    this.setState(Object.assign(resp, state));
  }

  exportData = (extraParams) => {
    const params =  {
      sort: this.state.sortProperties
    };

    if (extraParams) {
      Object.assign(params, { extraParams });
    } else {
      this.applyExtraParams(params);
    }

    this.setState(
      { loadingData: true },
      () => {
        client
          .loadGridData(this.getApiPath(), params)
          .done((response) => this.exportAction(response, params))
          .fail((error) => this.showError(error, { loadingData: false }));
      }
    );
  }
/*
  exportAction = (response, params) => {
    this.setState({ loadingData: false });
    const excel = new Excel();
    excel.addJsonData(response.data, this.getExportHeaders(response), this.getExportSheetName());
    excel.saveAs(this.getExportFileName(params));
  }
*/
  getExportHeaders(response) {
    const headers = {};
    this.getColumns().forEach(column => {
      headers[column.name] = column.title;
    });

    return headers;
  }
  
  getExportSheetName() {
    return 'Sheet 1';
  }
/*
  static getTimeStamp() {
    return moment().format('YYYYMMDDHHmm');
  }

  getExportFileName() {
    return `export_${Grid.getTimeStamp()}.xlsx`;
  }
*/
  getExportButton(className) {
    return (
      <Button color="default" className={className} onClick={() => this.exportData()}>
        <i className="fa fa-download"></i> Export
      </Button>
    );
  }

  onGetPage = (pageNumber) => {
    this.loadPageData(pageNumber);
  }

  onSort = (column, asc) => {
    const columns = update(this.state.columns, {
      [column]: {
        asc: {
          $set: asc
        }
      }
    });

    this.setState({
      columns,
      sortProperties: this.getSortProperties(columns)
    }, () => this.loadPageData(this.state.currentPage));
  }

  getSortProperties(columns) {
    const sortProperties = {};
    Object.keys(columns).forEach((column) => {
      if (columns[column].sortable && columns[column].asc !== null) {
        sortProperties[columns[column].name] = columns[column].asc;
      }
    });
    return sortProperties;
  }

  getRowStyler() {
    return;
  }

  getAlertMessage() {
    return (
      <AlertMessage 
        color={this.state.success ? 'info' : 'danger' }
        message={this.state.message}
        closeHandler={this.removeMessage}
      />
    );
  }

  getLetterFilterHandler = (char) => {
    return () => {
      let newChar = char;
      const currentChar = this.getExtraParam('letterFilter');
      if (currentChar !== undefined && currentChar === char) {
        newChar = undefined;
      }
      this.setExtraParam('letterFilter', newChar);
    }
  }

  getGridHeaderLetterFilter() {
    if (!this.state.letterFilter) {
      return null;
    }

    const letter = this.state.extraParams.letterFilter || '';

    return (
      <h3 className="letter-filter">
        {headerFilter.split('').map((char, idx) =>
          (
            <button
              key={idx}
              type="button"
              className={classNames('btn', 'btn-sm', 'btn-link', { active: letter === char })}
              onClick={this.getLetterFilterHandler(char)}
            >
              {char}
            </button>
          )
        )}
    </h3>
    );
  }

  getGridHeader = () => {
    if (!this.state.title) {
      return null;
    }

    return (
      <CardHeader onClick={this.toggle} className="cursor-pointer">
        <h5>
          {this.state.title}
          <i className={classNames('fa', 'float-right', 'collapse-icon', {
              'fa-caret-down': this.state.collapse,
              'fa-caret-up': !this.state.collapse
            })} aria-hidden="true"
          />
        </h5>
      </CardHeader>
    );
  }

  getGrid() {
    return (
      <div className={classNames(
        'jtn-grid', this.props.className, this.state.className, { 'row-selectable': this.isRowSelectable() }
        )}
      >
        <Card className="border-0">
          {this.getGridHeader()}
          <Collapse isOpen={this.state.collapse}>
            <CardBody>
              <ComponentContext.Provider value={{ components: this.components }}>
                <Desktop>{this.getGridHeaderLetterFilter()}</Desktop>
                <Tablet>{this.getGridHeaderLetterFilter()}</Tablet>
                {this.getHeaderComponent()}
                {this.getHeader()}
                {this.getBody()}
                {this.getPagination()}
              </ComponentContext.Provider>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }

  getHeader() {
    return (
      <div>
        <Desktop>{this.getDesktopHeader()}</Desktop>
        <Tablet>{this.getTabletHeader()}</Tablet>
        <Mobile>{this.getMobileHeader()}</Mobile>
      </div>
    );
  }

  getDesktopHeader() {
    return <this.components.Head columns={this.state.columns} onSort={this.onSort} />;
  }

  getTabletHeader() {
    return <this.components.Head columns={this.state.columns} onSort={this.onSort} />;
  }

  getMobileHeader() {
    return <this.components.Head columns={this.state.columns} onSort={this.onSort} />;
  }

  getHeaderComponent() {
    if (this.props.headerComponent) {
      return this.props.headerComponent;
    }
  }

  getBody() {
    if (this.state.loadingData === null) {
      return null;
    }

    return (
      <div className="grid-rows">
        <Loader loading={this.state.loadingData} />
        <Desktop>{this.getDesktopBody()}</Desktop>
        <Tablet>{this.getTabletBody()}</Tablet>
        <Mobile>{this.getMobileBody()}</Mobile>
      </div>
    );
  }

  getDesktopBody() {
    return <this.components.Body
      columns={this.state.columns}
      data={this.state.data}
      recordCount={this.state.recordCount}
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      onClick={this.onRowClick}
      loading={this.state.loadingData}
      rowStyler={this.getRowStyler()}
      adjustTotal={this.props.adjustTotal}
      extraParams={this.state.extraParams}
    />;
  }

  getTabletBody() {
    return <this.components.Body
      columns={this.state.columns}
      data={this.state.data}
      recordCount={this.state.recordCount}
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      onClick={this.onRowClick}
      loading={this.state.loadingData}
      rowStyler={this.getRowStyler()}
      adjustTotal={this.props.adjustTotal}
      extraParams={this.state.extraParams}
    />;
  }

  getMobileBody() {
    return <this.components.Body
      columns={this.state.columns}
      data={this.state.data}
      recordCount={this.state.recordCount}
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      onClick={this.onRowClick}
      loading={this.state.loadingData}
      rowStyler={this.getRowStyler()}
      adjustTotal={this.props.adjustTotal}
      extraParams={this.state.extraParams}
    />;
  }

  getPagination() {
    return this.props.paging && (
      <div>
        <Desktop>{this.getDesktopPagination()}</Desktop>
        <Tablet>{this.getTabletPagination()}</Tablet>
        <Mobile>{this.getMobilePagination()}</Mobile>
      </div>
    );
  }

  getDesktopPagination() {
    return <Pagination
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      recordCount={this.state.recordCount}
      getPage={this.onGetPage}
    />;
  }

  getTabletPagination() {
    return <Pagination
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      recordCount={this.state.recordCount}
      getPage={this.onGetPage}
    />;
  }

  getMobilePagination() {
    return <Pagination
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      recordCount={this.state.recordCount}
      getPage={this.onGetPage}
    />;
  }

  render() {
    if (!Object.keys(this.state.columns).length) {
      return null;
    }

    return (
      <Row>
        <Col>
          {this.getAlertMessage()}
          {this.getGrid()}
        </Col>
      </Row>
    );
  }
}
