import React, { Component } from 'react';
import classNames from 'classnames';
import {
  Container, Row, Col, Card, CardBody, Button, Form
} from 'reactstrap';

import FormLoginRow from './FormLoginRow';
import AlertMessage from '../Message';
import { client } from '../Client';

export default class LoginForm extends Component {

  state = {
    email: '',
    password: '',
    errors: {},
    message: '',
    success: true,
    sendingData: false,
    clientSideValidated: false
  };

  removeMessage = () => {
    this.setState({ message: '', success: true });
  }

  sendFormData = () => {
    const { email, password } = this.state;

    return client.login({ email, password });
  }

  successAction = (response) => {
    const locationState = this.props.location.state;
    const pathname = (
      locationState && locationState.from && locationState.from.pathname
    );

    this.props.history.replace(pathname || '/');
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.form.checkValidity() === false) {
      this.setState({ clientSideValidated: true });
      return;
    }

    this.setState({ clientSideValidated: false });
    this.submitForm();
  };

  submitForm() {
    this.setState({ sendingData: true, message: '', errors: {} }, () => {
      this.sendFormData()
        .done(this.submitDone)
        .fail(this.showError);
    });
  }

  submitDone = (response) => {
    this.setState({
      sendingData: false
    }, () => this.successAction(response));
  }

  showError = (error) => {
    const state = error.responseJSON ? error.responseJSON : { success: false, message: error.responseText };
    Object.assign(state, { sendingData: false });

    this.setState(state);
  }

  render() {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col md="5">
            <Form
              onSubmit={this.onFormSubmit}
              innerRef={(comp) => { this.form = comp; }}
              noValidate
              className={classNames('login', { 'was-validated': this.state.clientSideValidated })}
            >
              <Card>
                <CardBody className="card-body">
                  <h2>Login</h2>
                  <p className="text-muted">Sign In to your account</p>

                    <AlertMessage
                      color={this.state.success ? 'info' : 'danger'}
                      message={this.state.message}
                      closeHandler={this.removeMessage}
                    />

                    <FormLoginRow 
                      cssClass="mb-3"
                      icon="@"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={this.state.email}
                      required
                      onChange={this.handleInputChange}
                      errors={this.state.errors}
                    />

                    <FormLoginRow
                      cssClass="mb-4"
                      icon={<i className="fa fa-lock" />}
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={this.state.password}
                      required
                      onChange={this.handleInputChange}
                      errors={this.state.errors}
                    />
                  <Row>
                    <Col xs="6">
                      <Button type="submit" color="primary" className="px-4" disabled={this.state.sendingData}>
                        Login
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}
