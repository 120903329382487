import React, { useState, useEffect } from 'react';
import { Alert, Row, Col, CustomInput, Form, FormGroup } from 'reactstrap';
import ProjectProgressChart from './ProjectProgressChart';
import ProjectProgressTable from './ProjectProgressTable';
import { client } from '../Client';
import { showError } from '../notify';

const statuses = {
  complete: 'Complete',
  screenout: 'Screenout',
  quotafull: 'Quotafull',
  incomplete: 'Incomplete',
  duplicate: 'Duplicate',
  total: 'TOTAL'
};

function MetricsRadio({ value, currentValue, label, onChange }) {
  return (
    <CustomInput
      type="radio" name="metrics" id={value}
      checked={currentValue === value}
      label={label}
      onChange={() => onChange(value)}
    />
  );
}

export default function ProjectProgress({ projectId }) {
  const [metrics, setMetrics] = useState('progressively');
  const [data, setData] = useState([]);
  const isDaily = metrics === 'daily';

  useEffect(() => {
    client.api('GET', `/project/${projectId}/progress`)
      .then(resp => setData(resp.data))
      .catch(showError);
  }, [projectId]);

  function getTableData() {
    if (isDaily) {
      return data;
    }

    let prevRow = {
      complete: 0, screenout: 0, quotafull: 0, incomplete: 0, duplicate: 0, total: 0
    };

    const tableData = data.map(row => {
      const progress = Object.fromEntries(
        Object
          .entries(row)
          .map(([key, val]) => [key, ['created', 'loi'].includes(key) ? val : Number(val) + Number(prevRow[key])])
      );

      prevRow = progress;

      return progress;
    });


    return tableData;
  }
  
  function getChartData() {
    const categories = [];
    const chartData = Object.fromEntries(
      Object.entries(statuses).map(([status, name]) => [status, { name, data: [] }])
    );

    getTableData().forEach(row => {
      categories.push(row.created);

      Object.keys(statuses).forEach(status => {
        chartData[status].data.push(isDaily ? Number(row[status]) : row[status]);
      });
    });

    return {
      categories,
      series: Object.values(chartData)
    };
  }

  return (
    <React.Fragment>
      <Row className="mt-5 border-bottom">
        <Col className="font-weight-bold">Progress</Col>
      </Row>
      {data.length === 0 ? (
        <Row className="mt-2">
          <Col>
            <Alert color="warning">Waiting for data</Alert>
          </Col>
        </Row>
      ) : (
        <React.Fragment>
          <Row>
            <Col xs="2" className="pt-2">
              <Form>
                <FormGroup>
                  <MetricsRadio value="progressively" currentValue={metrics} label="Progressively" onChange={setMetrics} />
                  <MetricsRadio value="daily" currentValue={metrics} label="Daily" onChange={setMetrics} />
                </FormGroup>
              </Form>
            </Col>
            <Col xs="10">
              <ProjectProgressChart data={getChartData()} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProjectProgressTable data={getTableData()} />
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
