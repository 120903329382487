import { toast as notify } from 'react-toastify';

// https://github.com/fkhadra/react-toastify

function showError(error) {
  if (error.responseJSON) {
    if (error.responseJSON.message) {
      notify.error(error.responseJSON.message);
    }

    if (error.responseJSON.errors) {
      Object.values(error.responseJSON.errors).forEach(msg => notify.error(msg));
    }
  } else {
    notify.error(error.responseText);
  }
}

export {
  notify,
  showError
};
