import React, { useState, useEffect } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
  Form, FormGroup, Input
} from 'reactstrap';

export default function SpecialRequestModal({ isOpen, toggle, value, onChange }) {
  const [specialRequest, setSpecialRequest] = useState(value);

  useEffect(() => {
    setSpecialRequest(value);
  }, [value]);

  function createSpecialRequest(event) {
    event.preventDefault();
    event.stopPropagation();

    onChange(specialRequest.trim());
    toggle();
  }

  function onCancel() {
    setSpecialRequest(value);
    onChange(value);
    toggle();
  }

  return (
    <Modal isOpen={isOpen} backdrop="static" keyboard={false} size="lg" >
      <Form onSubmit={createSpecialRequest} autoComplete="off" >
        <ModalHeader>Special request</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input
              type="textarea"
              placeholder="Enter your request"
              value={specialRequest}
              onChange={e => setSpecialRequest(e.target.value)}
              rows="6"
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">Save</Button>{' '}
          <Button color="secondary" onClick={onCancel}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
