import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Loader extends Component {
  static propTypes = {
    loading: PropTypes.bool
  };

  render() {
    return (
      <div className={classNames('loader', 'd-flex', 'justify-content-center', 'align-items-center', { loading: this.props.loading })}>
        <i className="fa fa-spinner fa-spin fa-fw" aria-hidden="true"></i>
        <span> Loading...</span>
      </div>
    );
  }
}
