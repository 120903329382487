import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Max number of boxes without the arrows
const pageBoxes = 7;

export class Pagination extends Component {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    recordCount: PropTypes.number.isRequired,
    getPage: PropTypes.func.isRequired
  };

  getClick(page) {
    return () => {
      this.props.getPage(page);
    }
  }

  getNav(key, page, text, disabled, className) {
    let link = (<a className="page-link" onClick={this.getClick(page)}><span>{text}</span></a>);
    if (disabled) {
      link = (<span className="page-link" >{text}</span>);
    }

    return (
      <li key={key} className={classNames('page-item', { disabled: disabled }, className)}>
        {link}
      </li>
    );
  }

  getFirst() {
    return this.getNav('first', 1, (<i className="fa fa-angle-double-left" />), this.props.currentPage === 1);
  }

  getPrev() {
    return this.getNav('prev', this.props.currentPage - 1, (<i className="fa fa-angle-left" />), this.props.currentPage === 1);
  }

  getNext() {
    const pages = Math.ceil(this.props.recordCount / this.props.pageSize);
    return this.getNav('next', this.props.currentPage + 1, (<i className="fa fa-angle-right" />), this.props.currentPage >= pages);
  }

  getLast() {
    const pages = Math.ceil(this.props.recordCount / this.props.pageSize);
    return this.getNav('last', pages, (<i className="fa fa-angle-double-right" />), this.props.currentPage >= pages);
  }

  getPages() {
    const pageCount = Math.ceil(this.props.recordCount / this.props.pageSize);
    let boxes = pageCount < pageBoxes ? pageCount : pageBoxes;
    const diff = Math.ceil((boxes / 2) - 1);

    let first = this.props.currentPage - diff;
    if (first <= 0) {
      first = 1;
    } else if (first + boxes > pageCount) {
      first = pageCount + 1 - boxes;
    }
    const pages = [];
    for(let i = 0; i < boxes; i++) {
      const pageNum = first + i;
      let box;
      if (this.props.currentPage === pageNum) {
        box = this.getNav(pageNum, pageNum, pageNum, false, 'active');
      } else if (i === 0 && first > 1) {
        box = this.getNav(pageNum, pageNum, '..', true);
      } else if (i + 1 === pageBoxes && first + i < pageCount) {
        box = this.getNav(pageNum, pageNum, '..', true);
      } else {
        box = this.getNav(pageNum, pageNum, pageNum, false);
      }
      pages.push(box);
    }
    return pages;
  }

  render() {
    if (!this.props.recordCount || !this.props.pageSize) {
      return null;
    }

    return (
      <nav className="pagination-nav">
        <ul className="pagination pagination-sm">
          {this.getFirst()}
          {this.getPrev()}
          {this.getPages()}
          {this.getNext()}
          {this.getLast()}
        </ul>
      </nav>
    );
  }
}
