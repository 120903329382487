import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export class HeadCell extends Component {
  static propTypes = {
    column: PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      sortable: PropTypes.bool.isRequired,
      asc: PropTypes.bool,
      align: PropTypes.string,
      width: PropTypes.number.isRequired,
      exactWidth: PropTypes.bool.isRequired,
      className: PropTypes.string
    }),
    onSort: PropTypes.func.isRequired
  };

  getSortIcon() {
    if (!this.props.column.sortable) {
      return;
    }

    return (
      <i
        className={classNames('fa', 'collapse-icon', {
          'fa-caret-down': this.props.column.asc === false,
          'fa-caret-up': this.props.column.asc === true
        })}
        aria-hidden="true"
      />
    );
  }

  onSort = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.props.column.sortable) {
      this.props.onSort(
        this.props.column.name,
        this.props.column.asc === null
          ? true
          : this.props.column.asc === true
            ? false
            :null
      );
    }
  }

  renderContent() {
    if (this.props.column.renderHeader && this.props.column.renderer) {
      return (
        <this.props.column.renderer
          row={{}}
          data={null}
          column={this.props.column}
          isHeader
          {...this.props.column.params}
        />
      );
    }

    return (
      <div className="head-cell">
        {this.props.column.title}
        {this.getSortIcon()}
      </div>
    );
  }

  render() {
    const style = {
      width: this.props.column.width
    };

    let alignClass = '';
    if (this.props.column.align) {
      alignClass = `justify-content-${this.props.column.align}`;
    }

    if (this.props.column.exactWidth) {
      style.flexGrow = 0;
    }

    return (
      <div
        className={classNames(
          'grid-cell', 'd-flex', { 'cursor-pointer': this.props.column.sortable }, alignClass, this.props.column.className
        )}
        onClick={this.onSort}
        style={style}
      >
        {this.renderContent()}
      </div>
    );
  }
}
