import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

export default class AlertMessage extends Component {

  static PpropTypes = {
    message: PropTypes.string,
    color: PropTypes.string,
    closeHandler: PropTypes.func,
    timeout: PropTypes.number
  }

  static defaultProps = {
    timeout: 10000,
    color: 'info'
  }

  constructor(props) {
    super(props);
    this.msg = React.createRef();
  }

  componentDidMount() {
    this.timeoutCloseHandler(this.props);
  }

  componentDidUpdate() {
    if (this.msg.current) {
      const current = $(this.msg.current);
      // check if alert is in modal
      if (current.parents('.modal-dialog').length) {
        this.msg.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        const top = current.offset().top - $('nav.navbar').outerHeight();

        window.scrollTo({ top, left: 0, behavior: 'smooth' });
      } 
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.timeoutCloseHandler(nextProps);
  }

  timeoutCloseHandler(props) {
    if (props.message && props.closeHandler && !this.timer) {
      this.timer = setTimeout(() => {
        this.clearTimer();
        props.closeHandler();
      }, props.timeout);
    }
  }

  render() {
    if (!this.props.message) {
      return null;
    }

    return (
      <Alert color={this.props.color} toggle={this.props.closeHandler} innerRef={this.msg}>
        {this.props.message}
      </Alert>
    );
  }
}
