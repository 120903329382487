import React, { useContext } from 'react';
import { Row, Col, Card, CardTitle, CardBody, Button, Table } from 'reactstrap';
import CheckoutContext from '../CheckoutContext';
import UserContext from '../UserContext';
import ConfirmCheckoutModal from './ConfirmCheckoutModal';
import useModal from './useModal';
import Price from './Price';
import { client } from '../Client';
import { notify, showError } from '../notify';

function hasSurvey(obj) {
  return Object.keys(obj || {}).length > 0;
}

function filterSamples(obj) {
  return Object.values(obj || {}).filter(({ result = {} }) => result.feasible > 0);
}

function calculateTotalPrice(survey, samples, reports) {
  let totalPrice = 0;

  if (hasSurvey(survey)) {
    totalPrice += survey.price || 0;
  }

  samples.forEach(sample => {
    totalPrice += sample.result.totalCost;
  });

  reports.forEach(report => {
    totalPrice += report.price;
  });

  return totalPrice;
}

export default function CheckoutRow() {
  const [showModal, toggleModal] = useModal();
  const { checkout, resetCheckout } = useContext(CheckoutContext);
  const { onTokenChange } = useContext(UserContext);
  const {
    diy_survey = {},
    sampling = {},
    reports = [],
    special = ''
  } = checkout;

  function onSuccessCheckout(resp) {
    toggleModal();
    resetCheckout(); // reset state
    // response schould contain token to reset survey
    onTokenChange(resp.data);
    notify.info('Your order was successful, project manager will contact you if there are any questions.');
  }

  function checkoutItems() {
    client.api('POST', '/diy/checkout', checkout)
      .then(onSuccessCheckout)
      .catch(showError);
  }

  const samples = filterSamples(sampling);

  if (!hasSurvey(diy_survey) && !samples.length && !reports.length && !special.length) {
    return null;
  }

  const totalPrice = calculateTotalPrice(diy_survey, samples, reports);

  return (
    <React.Fragment>
      <Row className="m-3">
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <CardTitle className="font-weight-bold">Order summary</CardTitle>
              <Row>
                <Col sm="12" md="10">
                  <Table size="sm" borderless >
                    <tbody>
                      {hasSurvey(diy_survey) &&
                        <tr>
                          <td>DIY Survey, {diy_survey.minCompletes || 0} completes</td>
                          <td><Price value={diy_survey.price} /></td>
                        </tr>
                      }
                      {samples.map(sample =>
                        <tr key={sample.country}>
                          <td>Sample - {sample.country}</td>
                          <td><Price value={sample.result.totalCost} /></td>
                        </tr>
                      )}
                      {reports.map(report =>
                        <tr key={report.name}>
                          <td>Report - {report.name}</td>
                          <td><Price value={report.price} /></td>
                        </tr>
                      )}
                      {special.length > 0 &&
                        <tr>
                          <td>
                            Request<br />
                            <pre>{special}</pre>
                          </td>
                          <td>{' '}</td>
                        </tr>
                      }
                      {totalPrice > 0 &&
                        <tr className="font-weight-bold">
                          <td>Total</td>
                          <td><Price value={totalPrice} /></td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </Col>
                <Col sm="12" md="2">
                  <Button onClick={toggleModal} outline >Checkout</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmCheckoutModal
        isOpen={showModal}
        toggle={toggleModal}
        onCheckout={checkoutItems}
        showProjectName={samples.length > 0}
      />
    </React.Fragment>
  );
}
