import React, { useState, createContext, useEffect } from 'react';
import { client } from '../Client';

export const TermsContext = createContext();

export function TermsContextProvider({ children }) {
  const [terms, setTerms] = useState('Loading data...');

  useEffect(() => {
    client.api('GET', '/terms')
      .then(resp => setTerms(resp.data))
      .catch(error => {
        console.error(error);
        setTerms('An error occurred while loading the data');
      });
  }, []);

  return (
    <TermsContext.Provider value={{ terms }}>
      {children}
    </TermsContext.Provider>
  );
}
