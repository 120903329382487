import React, { useContext } from 'react';
import {
  CardImg, CardTitle, CardText, Card, CardBody, Button 
} from 'reactstrap';
import offerImg from './offers.png';
import SpecialRequestModal from './SpecialRequestModal';
import CardButton from './CardButton';
import SuccessCheck from './SuccessCheck';
import useModal from './useModal';
import CheckoutContext from '../CheckoutContext';

export default function SpecialServicesCard() {
  const { checkout: { special = '' }, updateCheckout } = useContext(CheckoutContext);
  const [showModal, toggleModal] = useModal();

  return (
    <React.Fragment>
      <Card className="text-center">
        {special.length > 0 && <SuccessCheck />}
        <CardImg top className="welcome-card-img" src={offerImg} alt="Special Services" />
        <CardBody className="d-flex flex-column">
          <CardTitle tag="h5">Special Services</CardTitle>
          <CardText tag="div">
            <div className="text-left">
              - Passive metering <br />
              - Augmented sample / special methodologies <br />
              - Other special requests <br />
            </div>
          </CardText>
          {special.length === 0 ? (
            <CardButton>
              <Button outline onClick={toggleModal}>START</Button>
            </CardButton>
          ) : (
            <CardButton>
              <Button outline onClick={toggleModal}>RESTART</Button>
              <Button outline color="warning" onClick={() => updateCheckout({ special: '' })}>Remove</Button>
            </CardButton>
          )}
        </CardBody>
      </Card>
      <SpecialRequestModal
        isOpen={showModal} toggle={toggleModal}
        value={special} onChange={value => updateCheckout({ special: value })}
      />
    </React.Fragment>
  );
}
