import util from 'util';

export function ucFirst(text) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

export function formatCurrency(amount) {
  const cost = typeof amount !== 'number' ? Number(amount) : amount;
  return util.format('$%s', cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
}

export function toNumber(num) {
  return typeof num !== 'number' ? Number(num) : num;
}

export function formatDate(data) {
  if (data !== null && data !== undefined) {
    let date;
    if (!(data instanceof Date)) {
      date = new Date(data);
    }

    return date.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
  }

  return '-';
}

export function composeVariables(variables) {
  return variables.reduce((accumulator, current) => {
    return `${accumulator}/{${current}}`;
  }, '');
}

export function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function randomIntInc(low, high) {
  return Math.floor((Math.random() * ((high - low) + 1)) + low);
}
