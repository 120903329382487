import { getDefaultSampleValues } from './SampleMarketModal';

export default function createSurveyMarket(diy_survey) {
  const { loi, ir, country, minCompletes: completes, gender, age } = diy_survey;
  const market = { ...getDefaultSampleValues(), key: 'jlsurvey', locked: true, loi, ir, country, completes };

  Object.assign(market.sample.gender, { values: gender });
  let minAge, maxAge;
  const quota = {};
  const quotaPercentage = Math.floor(100 / age.length);

  let prevMin = 0, prevMax = 0;
  age.sort(([first], [second]) => first - second).forEach(([min, max], idx) => {
    const isFirst = idx === 0;
    const isLast = idx === age.length - 1;

    if (isFirst) {
      minAge = min;
    } 

    if (!isFirst && min - prevMax > 1) {
      quota[`${prevMax + 1}-${min - 1}`] = 0;
    }

    quota[`${min}-${max}`] = isLast ? (100 - idx * quotaPercentage) : quotaPercentage;

    prevMin = min;
    prevMax = max;
    if (isLast) {
      maxAge = max;
    }
  });

  Object.assign(market.sample.age, { values: [minAge, maxAge], type: 'quota', quota });

  return market;
}
