import React, { useContext } from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
  Col, Form, FormGroup, Label, Input
} from 'reactstrap';
import CheckoutContext from '../CheckoutContext';

export default function ConfirmCheckoutModal({ isOpen, toggle, onCheckout, showProjectName = false }) {
  const {
    checkout: { project_name = '', po = '', job_number = '' }, updateCheckout
  } = useContext(CheckoutContext);

  function onConfirmCheckout(event) {
    event.preventDefault();
    event.stopPropagation();

    onCheckout();
  }

  return (
    <Modal isOpen={isOpen} backdrop="static" keyboard={false} >
      <Form onSubmit={onConfirmCheckout} autoComplete="off" >
        <ModalHeader>Confirm order</ModalHeader>
        <ModalBody>
          <p className="text-info">Are you sure you want to order the selected items ?</p>
          {showProjectName &&
            <div>
              <FormGroup row>
                <Label for="country" sm={3}>Project name</Label>
                <Col sm={9}>
                  <Input
                    type="text" name="project_name"
                    value={project_name}
                    onChange={e => updateCheckout({ project_name: e.target.value })}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="job_number" sm={3}>Job number</Label>
                <Col sm={5}>
                  <Input
                    type="text" name="job_number"
                    value={job_number}
                    onChange={e => updateCheckout({ job_number: e.target.value })}
                  />
                </Col>
              </FormGroup>
                <FormGroup row>
                <Label for="po" sm={3}>PO</Label>
                <Col sm={5}>
                  <Input
                    type="text" name="po"
                    value={po}
                    onChange={e => updateCheckout({ po: e.target.value })}
                  />
                </Col>
              </FormGroup>
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="success" outline>Confirm</Button>{' '}
          <Button onClick={toggle} outline>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
