import React from 'react';
import { Modal, ModalBody, Row, Col, Spinner } from 'reactstrap';

export default function LoadingModal({ isOpen, loadingText = 'Loading data ...' }) {
  return (
    <Modal isOpen={isOpen} backdrop="static" keyboard={false} >
      <ModalBody>
        <Row>
          <Col xs="auto"><Spinner color="success" size="sm" /></Col>
          <Col className="font-weight-bold text-center">{loadingText}</Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}