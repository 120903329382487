import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ComponentContext } from './ComponentContext';

export class Body extends Component {
  static contextType = ComponentContext;

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    rowStyler: PropTypes.any,
    adjustTotal: PropTypes.string
  };

  initSummarize(sumRow) {
    const summarize = [];
    Object
      .keys(this.props.columns)
      .forEach((columnIndex) => {
        const column= this.props.columns[columnIndex];
        if (column.summarize) {
          summarize.push(column.dataField);
        }

        sumRow[column.dataField] = column.summarize ? 0 : null;
      });
    
    return summarize;
  }

  updateSumRow(sumRow) {
    if (this.props.adjustTotal !== undefined) {
      const adjustText = this.props.adjustTotal;
      let adjust;
      let op;
      if (adjustText.endsWith('%')) {
        adjust = Number(adjustText.substr(0, adjustText.length -1));
        op = 'per';
      } else {
        adjust = Number(adjustText);
        op = 'add';
      }

      if (adjust > 0) {
        Object.keys(sumRow).forEach((field) => {
          if (op === 'per') {
            sumRow[field] *= (100 - adjust) / 100;
          } else {
            sumRow[field] -= adjust;
          }
        });  
      }
    }
  }

  render() {
    if (this.props.loading && !this.props.data.length) {
      return '';
    }

    if (!Array.isArray(this.props.data) || !this.props.data.length) {
      return <this.context.components.NoRecord />;
    }

    const sumRow = {};
    const summarize = this.initSummarize(sumRow);

    const rows = this.props.data.map((row, idx) => {
      summarize.forEach((dataField) => {
        sumRow[dataField] += row[dataField] ? Number(row[dataField]) : 0;
      });

      return (
        <this.context.components.Row
          key={idx}
          row={row}
          className={classNames({ loading: this.props.loading })}
          columns={this.props.columns}
          onClick={this.props.onClick}
          rowStyler={this.props.rowStyler}
          rowCount={this.props.recordCount}
          currentPage={this.props.currentPage}
          pageSize={this.props.pageSize}
          rowIndex={idx}
        />
      );
    });

    if (summarize.length) {
      this.updateSumRow(sumRow);
      rows.unshift(
        <this.context.components.Row
          key={this.props.data.length}
          row={sumRow}
          className={classNames('grid-sum-row', { loading: this.props.loading })}
          columns={this.props.columns}
          summary
        />
      );
    }

    return rows;
  }
}
