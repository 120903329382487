import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import UserContext from '../UserContext';

export default function WelcomeRow() {
  const { user: { first_name }} = useContext(UserContext);

  return (
    <Row className="text-center">
      <Col><h2>Hi {first_name}!</h2></Col>
    </Row>
  );
}
