import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { client } from '../Client';

export default class PrivateRoute extends Component {
  constructor(props) {
    super(props);

    this.authHandler = this.authHandler.bind(this);
    
    client.addAuthenticationListener(this.authHandler);
    this.state = {
      validated: false
    };
  }

  authHandler() {
    this.setState({
      validated: true
    })
  }

  render() {
    const { component: Component, ...rest } = this.props;
    if (!this.state.validated) {
      return <Route {...rest} render={props => (<div>Validating authentication...</div>)} />;
    } else if (client.isAuthenticated()) {
      return <Route {...this.props} />;
    } else {
      return <Redirect to={{
        pathname: '/login',
        state: { from: this.props.location }
      }} />;
    }
  }
}
