import React from 'react';

const genderMap = { m: 'Male', f: 'Female' };
const regionMap = { region1: 'Level 1', region2: 'Level 2', custom: 'Custom' };
const deviceMap = { pc: 'PC', tablet: 'Tablet', mobile: 'Mobile' };

// {country}; {gender 1} & {gender 2}; {age from} – {age to}; IR: {IR}; LOI: {LOI} min; Region: {region level selected}; {device 1}, {device 2}, {device 3}

function getTextValues(obj, map, separator = ', ') {
  return (obj.values || []).map(v => map[v]).join(separator);
}

export default function SampleMarketTextSummary({ market, info = {} }) {
  const {ir, loi, sample: {age, gender, region, device}} = market;
  const [ageMin = 0, ageMax = 0] = age.values || [];

  return (
    <span className="text-info">
      {info.title || market.country}; {getTextValues(gender, genderMap, ' & ')}; {ageMin}-{ageMax}; IR: {ir || 0}%; LOI: {loi || 0} min; Region: {getTextValues(region, regionMap)}; {getTextValues(device, deviceMap)}
    </span>
  );
}
