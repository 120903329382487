import React from 'react';
import { Grid } from '../grid';
import DateFormatter from '../DateFormatter';
import ProjectStatus from './ProjectStatus';

export default class ProjectGrid extends Grid {

  get gridName() {
    return 'projectGrid';
  }

  getApiPath = () => {
    return '/project';
  }

  getBasePath = () => {
    return '/project';
  }

  marketsRenderer = ({ data }) => {
    return (
      <span>{Array.isArray(data) && data.length ? data.join(' ') : '-'}</span>
    );
  }

  completesRenderer = ({ row: { complete, quota, feasible } }) => {
    return (
      <span className="font-weight-bold">{complete} / {feasible > quota ? quota : feasible}</span>
    );
  }

  getColumns() {
    return [{
      name: 'id',
      title: 'ID',
      width: 250
    }, {
      name: 'name',
      title: 'Project Name',
      width: 250
    }, {
      name: 'country',
      title: 'Country',
      width: 100
    }, {
      name: 'complete',
      title: 'completes',
      renderer: this.completesRenderer,
      width: 80
    }, {
      name: 'status',
      title: 'status',
      renderer: ProjectStatus,
      width: 80
    }, {
      name: 'created',
      title: 'Created',
      width: 80,
      renderer: DateFormatter,
      asc: false
    }];
  }
}
